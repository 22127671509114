import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';


export interface infoBookerState {
    nameBooker: string,
    phoneBooker: string,
    mailBooker: string,
    nameBill: string,
    nameCompany: string,
    taxCode: string,
    addressbill: string,
    bookerIsPassenger: boolean,
    exportBillCompany: boolean,
    companyEmail: string,
    companyPhone: string,
}

const initialState: infoBookerState = {
    nameBooker: "",
    phoneBooker: "",
    mailBooker: "",
    nameBill: "",
    nameCompany: "",
    taxCode: "",
    addressbill: "",
    bookerIsPassenger: false,
    exportBillCompany: false,
    companyEmail: '',
    companyPhone: ''
}

const inforBookerSlice = createSlice({
    name: 'info-booker',
    initialState,
    reducers: {
        setInfoBooker: (state, action) => {
            state.addressbill = action.payload.addressbill
            state.nameBill = action.payload.nameBill
            state.taxCode = action.payload.taxCode
            state.mailBooker = action.payload.mailBooker
            state.nameCompany = action.payload.nameCompany
            state.nameBooker = action.payload.nameBooker
            state.phoneBooker = action.payload.phoneBooker
            state.companyEmail = action.payload.companyEmail
            state.companyPhone = action.payload.companyPhone
        },
        setBookerIsPassenger: (state, action) => {
            state.bookerIsPassenger = action.payload
        },
        setExportBillCompany: (state, action) => {
            state.exportBillCompany = action.payload
        },
        clearInfoBooker: (state, action) => {
            state.exportBillCompany = false
            state.bookerIsPassenger = false
            state.addressbill = ''
            state.nameBill = ''
            state.taxCode = ''
            state.mailBooker = ''
            state.nameCompany = ''
            state.nameBooker = ''
            state.phoneBooker = ''
        }
    },
});

export const getInfoBooker = (state: RootState): infoBookerState => state.infoBooker
export const getBookerIsPassenger = (state: RootState):boolean => state.infoBooker.bookerIsPassenger
export const getExportBillCompany = (state: RootState):boolean => state.infoBooker.exportBillCompany
export const { setInfoBooker, setBookerIsPassenger, setExportBillCompany ,clearInfoBooker} = inforBookerSlice.actions;
export default inforBookerSlice.reducer;