import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import HeaderMobile from './HeaderMobile';
import { AppContext } from 'src/contexts/AppProvider';
import { settingLang } from 'src/locales/i18n';
import { useTranslation } from 'react-i18next';
import {  homePaths } from '@app/router/path';

const Header = () => {
    const { changeLanguage, i18nValue } = useContext(AppContext)
    const [language, setValueLanguage] = useState(`${i18nValue}`)
    const {t:trans} = useTranslation();
    const handleChangeLanguage = (lang: string) => {
        changeLanguage(String(lang))
    }
    return (
        <div id="header">
            <div className='header-curve'>
                <img src="/assets/png/curveheader.png" alt="" />
            </div>
            <div className="header-container container">
                <div id="header-left">
                    <a href="/">
                        <img src="/assets/png/logo.png" alt="lighning68" />
                    </a>
                </div>
                <div className="divider"></div>
                <div id="header-right">
                    <div className="header-top">
                        <div className="header-top-nav">
                            <div className="header-top-nav-item">
                                <Link to={homePaths.baseurl+homePaths.introduce}>{trans('header.top.intro')}</Link>
                            </div>
                            <div className="header-top-nav-item">
                                <Link to={homePaths.baseurl+homePaths.guide}>{trans('header.top.travel')}</Link>
                            </div>
                            <div className="header-top-nav-item">
                                <Link to={homePaths.baseurl+homePaths.libary}>{trans('header.top.libaryImage')}</Link>
                            </div>
                            <div className="header-top-nav-item">
                                <Link to={homePaths.baseurl+homePaths.contact}>{trans('header.top.contact')}</Link>
                            </div>
                            <div className="header-top-nav-item">
                                <Link to={homePaths.baseurl+homePaths.hiring}>{trans('header.top.hiring')}</Link>
                            </div>
                        </div>
                        <div className="header-top-circle">
                            <button>
                                <img
                                    src="/assets/png/search.png"
                                    alt="search button"
                                    style={{ width: '20px', height: '20px' }}
                                />
                            </button>
                            <button onClick={() => handleChangeLanguage(settingLang[0].value)}>
                                <img src="/assets/png/vnflag.png" alt="vi" />
                            </button>
                            <button onClick={() => handleChangeLanguage(settingLang[1].value)}>
                                <img src="/assets/png/enflag.png" alt="en" />
                            </button>
                        </div>
                    </div>
                    <div className="header-bot">
                        <div className="bot-link">
                            <Link to={homePaths.baseurl}>{trans('header.bot.home')}</Link>
                        </div>
                        <div className="bot-link">
                        <Link to={homePaths.bookingurl}>{trans('header.bot.booking')}</Link>
                        </div>
                        <div className="bot-link">
                        <Link to={homePaths.baseurl+homePaths.guide}>{trans('header.bot.tour')}</Link>
                        </div>
                        <div className="bot-link">
                        <Link to={homePaths.baseurl+homePaths.service}>{trans('header.bot.service')}</Link>
                        </div>
                        <div className="bot-link">
                        <Link to={homePaths.baseurl+homePaths.price}>{trans('header.bot.pricing')}</Link>
                        </div>
                        <div className="bot-link">
                            <Link to={homePaths.baseurl+homePaths.sale}>{trans('header.bot.saleoff')}</Link>
                        </div>
                        <div className="bot-link">
                            <Link to={homePaths.baseurl+homePaths.new}>{trans('header.bot.news')}</Link>
                        </div>
                    </div>
                </div>
                <HeaderMobile changelang={handleChangeLanguage} trans={trans}/>
            </div>
        </div>
    );
};

export default Header;
