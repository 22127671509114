/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

const initialState = {
    data: {},
    seatChoose: [],
    seatChooseRoundTrip: [],
    isAccessInfo: false,
    isAccessInfoRoundTrip: false,
    isAccessSeatRoundTrip: false,
    seats: [],
    seatsRoundTrip: [],
    isOrder: false,
    holdingSeatChoose: [],
    holdingSeatChooseRoundTrip: [],
    seatsInTicket: [],
    seatsInTicketRoundTrip: [],
    priceSettings: {},
};

const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        actionSetData: (state, action) => {
            state.data = action.payload;
        },
        actionSetSeat: (state, action) => {
            state.seatChoose = action.payload;
        },
        actionSetAccessInfo: (state, action) => {
            state.isAccessInfo = action.payload;
        },
        actionSetSeatInfo: (state, action) => {
            state.seats = action.payload;
        },
        actionSetSeatRoundTrip: (state, action) => {
            state.seatChooseRoundTrip = action.payload;
        },
        actionSetAccessInfoRoundTrip: (state, action) => {
            state.isAccessInfoRoundTrip = action.payload;
        },
        actionSetSeatInfoRoundTrip: (state, action) => {
            state.seatsRoundTrip = action.payload;
        },
        actionSetAccessSeatRoundTrip: (state, action) => {
            state.isAccessSeatRoundTrip = action.payload;
        },
        actionSetAccessOrder: (state, action) => {
            state.isOrder = action.payload;
        },
        actionSetHoldingSeatChoose: (state, action) => {
            state.holdingSeatChoose = action.payload;
        },
        actionSetHoldingSeatChooseRoundTrip: (state, action) => {
            state.holdingSeatChooseRoundTrip = action.payload;
        },
        actionSetSeatsInTicket: (state, action) => {
            state.seatsInTicket = action.payload;
        },
        actionSetSeatsInTicketRoundTrip: (state, action) => {
            state.seatsInTicketRoundTrip = action.payload;
        },
        actionSetPriceSettings: (state, action) => {
            state.priceSettings = action.payload;
        },
        clearData: (state, action) => {
            state.data = {};
            state.seatChoose = [];
            state.seatChooseRoundTrip = [];
            state.isAccessInfo = false;
            state.isAccessInfoRoundTrip = false;
            state.isAccessSeatRoundTrip = false;
            state.seats = [];
            state.seatsRoundTrip = [];
            state.isOrder = false;
            state.holdingSeatChoose = [];
            state.holdingSeatChooseRoundTrip = [];
            state.seatsInTicket = [];
            state.seatsInTicketRoundTrip = [];
            state.priceSettings = {};
        },
    },
});

// action
export const {
    actionSetData,
    actionSetSeat,
    actionSetAccessInfo,
    actionSetSeatInfo,
    actionSetSeatRoundTrip,
    actionSetAccessInfoRoundTrip,
    actionSetSeatInfoRoundTrip,
    actionSetAccessSeatRoundTrip,
    actionSetAccessOrder,
    actionSetHoldingSeatChoose,
    actionSetHoldingSeatChooseRoundTrip,
    actionSetSeatsInTicket,
    actionSetSeatsInTicketRoundTrip,
    actionSetPriceSettings,
    clearData,
} = bookingSlice.actions;

//selector
export const getData = (state:RootState) => state.booking.data;
export const getSeatChoose = (state:RootState) => state.booking.seatChoose;
export const getIsAccessInfo = (state:RootState) => state.booking.isAccessInfo;
export const getSeatInfo = (state:RootState) => state.booking.seats;
export const getSeatChooseRoundTrip = (state:RootState) => state.booking.seatChooseRoundTrip;
export const getIsAccessInfoRoundTrip = (state:RootState) => state.booking.isAccessInfoRoundTrip;
export const getSeatInfoRoundTrip = (state:RootState) => state.booking.seatsRoundTrip;
export const getIsAccessSeatRoundTrip = (state:RootState) => state.booking.isAccessSeatRoundTrip;
export const getHoldingSeatChoose = (state:RootState) => state.booking.holdingSeatChoose;
export const getHoldingSeatChooseRoundTrip = (state:RootState) => state.booking.holdingSeatChooseRoundTrip;
export const getIsAccessOrder = (state:RootState) => state.booking.isOrder;
export const getSeatsInTicket = (state:RootState) => state.booking.seatsInTicket;
export const getSeatsInTicketRoundTrip = (state:RootState) => state.booking.seatsInTicketRoundTrip;
export const getPriceSettings = (state:RootState) => state.booking.priceSettings;

// reducer
const bookingReducer = bookingSlice.reducer;
export default bookingReducer;
