// import styling
// import 'antd/dist/reset.css'
import '@assets/styles/main.scss'

import AuthLayout from "@app/components/layouts/auth/AuthLayout";
import NotFound from "@views/not-found/NotFound";
import { useMemo } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { routes } from "./app/router";
import PrivateRoute from "./app/router/PrivateRoute";
import PublicRoute from "./app/router/PublicRoute";
import BookingMainLayout from '@app/components/layouts/booking/MainLayout';
import { appPaths } from '@app/router/path';
import EndLayout from '@app/components/layouts/booking/EndLayout';
import AppProvider from './contexts/AppProvider';
import CheckTicketLayout from '@app/components/layouts/booking/CheckTicketLayout';
function App() {
  const params = useParams()
  const location = useLocation();
  const getLayout = useMemo((): JSX.Element => {
    //fix route
    if(location.pathname.includes('auth')) {
      return <AuthLayout />;
    }
    else if(location.pathname.includes(appPaths.completePayment)) {
      return <EndLayout />;
    }else if(location.pathname.includes(appPaths.checkTicket)) {
      return <CheckTicketLayout />;
    }
    // location.pathname
    // if(location.pal)
    window.scrollTo({ top: 400, behavior: 'smooth' })
    return <BookingMainLayout/>
  }, [location]);
  return (
    <AppProvider>
      <Routes>
        <Route path="/" element={getLayout}>
          {routes.map((route) => (
            <Route
            key={route.key}
            path={route.path}
            element={
              route.public ? (
                <PublicRoute>
                    <route.component />
                  </PublicRoute>
                ) : (
                  <PrivateRoute>
                    <route.component />
                  </PrivateRoute>
                )
              }
              />
              ))}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AppProvider>
  );
}

export default App;
