import store from '@store/index'
import { ConfigProvider } from 'antd'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    {/* <PersistGate
      loading={null}
      persistor={persistor}
    > */}
      <Router>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#9BB73F',
              fontFamily: 'Meirio, sans-serif'
            }
          }}
        >
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
          >
            <App />
          </GoogleReCaptchaProvider>
        </ConfigProvider>
      </Router>
    {/* </PersistGate> */}
  </Provider>
);
