import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authApi } from "./api/auth.api";
import { bookingApi, searchBoatApi } from "./api/booking.api";
import auth from "./slices/auth.slice";
import bookingReducer from "./slices/booking.slice";
import infoPassengerSlice from "./slices/infoPassenger.slice";
import inforBooker from "./slices/inforBooker.slice";
import searchboatSlice from "./slices/searchboat.slice";
import seatsSlice from "./slices/seats.slice";
import selectedBoatSlice from "./slices/selectedBoat.slice";

const persistConfigBooking = {
  key: "admin-booking",
  storage,
  whitelist: [
    "data",
    "seatChoose",
    "isAccessInfo",
    "seats",
    "seatChooseArea",
    "isAccessInfoArea",
    "seatsArea",
    "isOrder",
    "holdingSeatChoose",
    "holdingSeatChooseArea",
    "seatsInTicket",
    "seatsInTicketArea",
    "priceSettings",
  ],
};

const persistConfigSearchBoat = {
  key: 'search-input',
  storage,
  whitelist: ['departure_date', "arrival_date", 'booking_number', "boat_route_id","arrival_boat_canbook","departure_boat_canbook"]
}

const persistConfigPassenger = {
  key: 'info-passenger',
  storage,
  whitelist: ['passengers']
}

const persistConfigSelectedBoats = {
  key: 'selected-boats',
  storage,
  whitelist: ['departure_boat', 'arrival_boat', 'departure_route_name', "arrival_route_name"]
}
const persistConfigSeats = {
  key: 'seats',
  storage,
  whitelist: ['seats','roundTripSeats','totalPrice']
}
const persistInfobooker = {
  key: 'info-booker',
  storage,
  whitelist: ['nameBooker',
    'phoneBooker',
    'mailBooker',
    'nameBill',
    'nameCompany',
    'taxCode',
    'addressbill',
    'bookerIsPassenger',
    'exportBillCompany',]
}
const rootReducer = combineReducers({
  auth: auth,
  [authApi.reducerPath]: authApi.reducer,
  [searchBoatApi.reducerPath]: searchBoatApi.reducer,
  [bookingApi.reducerPath]: bookingApi.reducer,
  searchBoat: persistReducer(persistConfigSearchBoat, searchboatSlice),
  booking: persistReducer(persistConfigBooking, bookingReducer),
  infoPassenger: persistReducer(persistConfigPassenger, infoPassengerSlice),
  infoBooker: persistReducer(persistInfobooker, inforBooker),
  selectedBoats: persistReducer(persistConfigSelectedBoats, selectedBoatSlice),
  seats: persistReducer(persistConfigSeats, seatsSlice)
});

export default rootReducer;
