export const  API= {
    // BASE_URL: "http://localhost:8000/api/v1/",
    BASE_URL: "https://portal.lightning68.com/api/v1/",
    // PUBLIC_URL:'http://localhost:8000/',
    PUBLIC_URL:'http://localhost:8000/',
    BOAT_ROUTE: "api-booking/boat-routes",
    SEARCH_BOAT: "api-booking/search",
    BOOKING_SEAT: "api-booking/seats",
    CAL_PRICE: "api-booking/calculator-price",
    BOOKING: "api-booking",
    HOLDING_SEAT: "api-booking/hold-ticket",
    USER: "api-booking/user-info",
    CANCEL_HOLDING_SEAT: "api-booking/cancel-hold-ticket",
    GET_SETTING_PRICE: "users/pricing-setting",
    GET_COUNTRY: "api-booking/get-list-countries",
    GET_DETAIL_ORDER: "api-booking/get-order-detail",
    UPDATE_ORDER: "api-booking/update-order",
    CANCEL_ORDER: "api-booking/cancel/order",
    CANCEL_TICKET: "api-booking/cancel/ticket",
    UPDATE_TICKET: "api-booking/update-info-ticket",
    GET_AGENT_BY_TYPE: 'api-booking/get-agent-by-type',
    GET_DATA_PRINT: "api-booking/get-data-print",
    GET_SCHEDULE: 'api-booking',
    CHECK_DETAIL_TICKET:"api-booking/search-order-by-code-and-phone",

}