import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import {  today } from '@app/utils/helperFunction';
import dayjs from 'dayjs';


export interface searchBoatState {
    departure_date: String,
    arrival_date?: String,
    boat_route_id: string,
    booking_number: number
}

const initialState: searchBoatState = {
    arrival_date: undefined,
    boat_route_id: '',
    booking_number: 1,
    departure_date: dayjs().format('DD/MM/YYYY')
};

const searchBoatSlice = createSlice({
    name: 'searchBoat',
    initialState,
    reducers: {
        setSearchBoat: (state, action: PayloadAction<searchBoatState>) => {            
            state.arrival_date = action.payload.arrival_date
            state.departure_date = action.payload.departure_date
            state.booking_number = action.payload.booking_number
            state.boat_route_id = action.payload.boat_route_id
        },
        clearSearchInput:(state, action)=>{
            state.arrival_date = undefined
            state.departure_date = dayjs().format('DD/MM/YYYY')
            state.booking_number = 1
            state.boat_route_id = ''
        }
    },
});

export const getSearchBoat = (state: RootState) => state.searchBoat
export const { setSearchBoat,clearSearchInput } = searchBoatSlice.actions;
export default searchBoatSlice.reducer;