import { TOKEN_KEY } from "@app/config/const";
import { useAppDispatch } from "@store/index";
import { logout } from "@store/slices/auth.slice";
import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { authPaths } from "./path";

const PrivateRoute = ({ children }: any) => {
  const dispatch = useAppDispatch();
  if (!sessionStorage.getItem(TOKEN_KEY)) {
    dispatch(logout());
    return <Navigate to={authPaths.login} replace />;
  }
  return (
    <Suspense
    //    fallback={<LoadContent />}
    >
      {children}
    </Suspense>
  );
};

export default React.memo(PrivateRoute);
