import { FunctionComponent, LazyExoticComponent, lazy } from "react";
import { appPaths, authPaths } from "./path";

const LoginPage = lazy(() => import("@views/auth-views/login/Login"));
const SearchBoat = lazy(() => import("@views/app-views/booking/search-boat/SearchBoat"));
const ChooseBoat = lazy(() => import("@views/app-views/booking/choose-boat/ChooseBoat"));
const InfoBooker = lazy(() => import("@views/app-views/booking/info-booker/InfoBooker"));
const ChooseSeat = lazy(() => import("@views/app-views/booking/choose-seat/ChooseSeat"));
const InfoPassenger = lazy(() => import("@views/app-views/booking/info-passenger/InfoPassenger"));
const Payment = lazy(() => import("@views/app-views/booking/payment/Payment"));
const CompletePayment = lazy(() => import("@views/app-views/booking/complete-payment/CompletePayment"));
const CheckTicket = lazy(() => import("@views/app-views/check-ticket-detail/CheckTicket"));

interface Route {
  key: string;
  path: string;
  component: LazyExoticComponent<FunctionComponent | React.FC>;
  public: boolean;
}

export const routes: Route[] = [
  {
    key: "login",
    path: authPaths.login,
    component: LoginPage,
    public: true,
  },
  // {
  //   key: "search-boat",
  //   path: appPaths.searchBoat,
  //   component: SearchBoat,
  //   public: true,
  // },
  {
    key:'choose-boat',
    path:appPaths.chooseBoat,
    component:ChooseBoat,
    public:true
  },
  {
    key: "home",
    path: appPaths.home,
    component: SearchBoat,
    public: true,
  },
  {
    key: "info-booker",
    path: appPaths.infoBooker,
    component: InfoBooker,
    public: true,
  },
  {
    key: "info-passenger",
    path: appPaths.InfoPassenger,
    component: InfoPassenger,
    public: true,
  },
  {
    key: "choose-seat",
    path: appPaths.chooseSeat,
    component: ChooseSeat,
    public: true,
  },
  {
    key: "payment",
    path: appPaths.payment,
    component: Payment,
    public: true,
  },
  {
    key: "complete-payment",
    path: appPaths.completePayment,
    component: CompletePayment,
    public: true,
  },
  {
    key: "check-ticket",
    path: appPaths.checkTicket,
    component: CheckTicket,
    public: true,
  },
];
