import { Col, Row } from 'antd';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { homePaths } from '@app/router/path';

const CardLink = () => {
    const {t: trans} = useTranslation();
    return (
        <div className="container wrapper-card">
            <Row gutter={[24, 16]}>
                <Col xs={24} md={8}>
                    <a href={homePaths.baseurl+homePaths.service}>
                        <div className="card-link">
                            <img src="/assets/images/lich-chay.jpg" alt="" />
                            <span />
                            <h4>{trans('cardlink.boatSchedule')}</h4>
                        </div>
                    </a>
                </Col>
                <Col xs={24} md={8}>
                    <a href={homePaths.baseurl+homePaths.price}>
                        <div className="card-link">
                            <img src="/assets/images/gia-ve.jpg" alt="" />
                            <span />
                            <h4>{trans('cardlink.pricing')}</h4>
                        </div>
                    </a>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8, offset: 0 }}>
                    <a href={homePaths.baseurl+homePaths.contact}>
                        <div className="card-link">
                            <img src="/assets/images/lien-he.jpg" alt="" />
                            <span />
                            <h4>{trans('cardlink.contact')}</h4>
                        </div>
                    </a>
                </Col>
            </Row>
        </div>
    );
};

export default CardLink;
