import { AnyAction, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { persistStore } from 'redux-persist'
import { Persistor } from 'redux-persist/es/types'
import rootReducer from './reducer'
import { authApi } from './api/auth.api'
import { bookingApi, searchBoatApi } from './api/booking.api'

const resettableRootReducer = (
  state: ReturnType<typeof rootReducer> | undefined,
  action: AnyAction
) => {
  if (action.type === 'authSlice/logout') {
    return rootReducer(undefined, action)
  }

  return rootReducer(state, action)
}

const store = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
    //   apiService.middleware,
      authApi.middleware,
      searchBoatApi.middleware,
      bookingApi.middleware,
    //   customerApi.middleware
    ])
})

export type RootState = ReturnType<typeof store.getState>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch

export const persistor: Persistor = persistStore(store)
export default store
