import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Slider from './slider/Slider'
import CardLink from "./card-link/CardLink";
const MainLayout = () => {
  return (
    <div className="app-main-container">
      <header>
        <Header />
        <Slider />
      </header>
      <main>
        <Outlet />
      </main>
      <section>
        <CardLink/>
      </section>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default React.memo(MainLayout);
