import { createContext, useCallback } from "react";
import { useTranslation } from "react-i18next";

interface IAppContext {
  i18nValue: string | null;
  changeLanguage: (value: string) => void;
}

const defaultState: IAppContext = {
  i18nValue: localStorage.getItem("i18nextLng"),
  changeLanguage: () => {
    return;
  },
};

export const AppContext = createContext<IAppContext>(defaultState);

export default function AppProvider({ children }: { children: JSX.Element }) {
  const { i18n } = useTranslation();
  const handleChangeLang = useCallback(
    (value: string) => {
      i18n.changeLanguage(value);
    },
    [i18n]
  );
  return (
    <AppContext.Provider
      value={{
        i18nValue: i18n.language,
        changeLanguage: handleChangeLang,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
