import { Seat } from '@app/types/order';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface selectedSeat {
    seats: Seat[],
    roundTripSeats?:Seat[],
    totalPrice:number,
}


const initialState: selectedSeat = {
    seats :[],
    roundTripSeats:[],
    totalPrice:0,
};

const seatSlice = createSlice({
    name: 'seats',
    initialState,
    reducers: {
        setseat: (state, action) => {
            state.seats = action.payload.seats
            state.roundTripSeats = action.payload.roundTripSeats
        },
        setTotalPrice:(state, action)=>{
            state.totalPrice = action.payload
        },
        clearSeat:(state,action) =>{
            state.seats = []
            state.roundTripSeats = []
            state.totalPrice = 0
        }
        
    },
});

export const getseat = (state: RootState):selectedSeat => state.seats
export const getTotalPrice = (state:RootState):number => state.seats.totalPrice
export const { setseat,clearSeat ,setTotalPrice} = seatSlice.actions;
export default seatSlice.reducer;