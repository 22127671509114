import {
    ILoginPayload,
  } from '@app/types/auth'
import { showNotification } from '@app/utils/helperFunction'
  import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
  } from '@reduxjs/toolkit/query/react'
  
  const fetchBaseQueryBasic = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json')
      headers.set('Accept', 'application/json')
      return headers
    }
  })
  
  const baseQueryAuth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    const result = await fetchBaseQueryBasic(args, api, extraOptions)
  
    if (result.error) {
      switch (result.error.status) {
        case 422:
          showNotification('error', (result?.error?.data as any)?.message)
          break
        default:
          break
      }
    }
    return result
  }
  
  export const authApi = createApi({
    baseQuery: baseQueryAuth,
    reducerPath: 'authApi',
    endpoints: (build) => ({
      login: build.mutation({
        query: (data: ILoginPayload) => ({
          url: '/api/auth/login',
          method: 'POST',
          body: data
        })
      }),
      getBoats: build.query({
        query: () => ({
          url: '/api/list-boat',
          method: 'GET',
          // body: data
        })
      }),
    })
  })
  
  export const {
    useLoginMutation,
    useGetBoatsQuery
  } = authApi
  