export const authPaths = {
  login: '/login',
};
export const appPaths = {
  home: '/',
  searchBoat: '/tim-kiem-tau',
  chooseBoat: '/chon-tau',
  infoBooker: '/thong-tin-nguoi-dat-ve',
  InfoPassenger: '/thong-tin-nguoi-di-tau',
  chooseSeat: '/chon-ghe',
  payment: '/thanh-toan',
  completePayment: '/hoan-tat-thanh-toan',
  checkTicket: '/tra-cuu-hoa-don',
};
export const homePaths ={
  baseurl :'https://lightning68.com',
  bookingurl: "https://booking.lightning68.com/",
  introduce: '/gioi-thieu',
  guide:'/cam-nang-du-lich',
  libary:'/thu-vien-hinh-anh',
  contact:'/lien-he',
  hiring:'/tuyen-dung',
  service:'/lich-trinh-tau-chay',
  price:'/gia-ve',
  sale:'/khuyen-mai',
  new:'/tin-tuc',
  policy:"/chinh-sach-an-toan-hanh-khach",
  baggage:"/chinh-sach-hanh-ly-ky-gui",
  ticket:'/chinh-sach-hoan-ve'
}