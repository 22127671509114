import { NotificationType } from "@app/types/common";
import { ConvertedData, OrderItem } from "@app/types/order";
import { Passengers } from "@store/slices/infoPassenger.slice";
import { infoBookerState } from "@store/slices/inforBooker.slice";
import { notification } from "antd";
import dayjs from "dayjs";

export const test = () => { };
/**
 * function show notification depend type
 * type: "success", "info", "warning", "error"
 * @param type
 * @param message
 */
export const showNotification = (type: NotificationType, message: string) => {
  notification.destroy();
  notification[type]({ message });
};

export const handleCheckPreEnterInput = (event: any) => {
  const charCode = event.which ? event.which : event.keyCode;
  if ((charCode < 48 || charCode > 57) && charCode !== 46) {
    event.preventDefault();
  }
};

export const handleConvertMoneyVND = (price: string | number) => {
  new Intl.NumberFormat().format(Number(price))
  return new Intl.NumberFormat().format(Number(price)) + ' VND';
};

export const handleConvertDate = (date: string) => {
  return date.split("/").reverse().join("-");
};

export const handleConvertDateToDDMMYYY = (dateString: String | undefined) => {
  const date = new Date(dateString?.toString() || '');
  return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-')
};

export const handleFormatDate = (dateString: string) => {
  const result = new Date(dateString).toJSON().slice(0, 10).split('-').reverse().join('/')
  return result
}
export const handleCheckTicket = (current: string) => {
  const today = new Date();
  const birthDate = new Date(handleConvertDate(current));
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  if (age >= 60) {
    return { value: "old_people", label: "Người già" };
  } else if (age <= 11) {
    return { value: "child", label: "Trẻ em" };
  } else {
    return { value: "adult", label: "Người trưởng thành" };
  }
};

export function convertArrayToObj(passengers: Passengers[], infoBooker: infoBookerState): { [key: string]: any } {
  const data: { [key: string]: any } = {};

  passengers.forEach((passenger, index) => {
    for (const key in passenger) {
      const newKey = `passengers.${index}.${key}`;
      data[newKey] = passenger[key as keyof Passengers];
     // store date in format DD/MM/YYYY
      if(key== 'dob'){
        data[newKey] = dayjs(data[newKey], 'DD/MM/YYYY').format("DD/MM/YYYY")
      }
    }
  });
  if (infoBooker.bookerIsPassenger) {
    data[`passengers.${0}.passengerName`] = infoBooker?.nameBooker;
    data[`passengers.${0}.email`] = infoBooker?.mailBooker;
    data[`passengers.${0}.number`] = infoBooker?.phoneBooker;
  }

  return data;
}
export const today = new Date().toISOString().split('T')[0];


export const formatDateDisplay = (str : String)=>{
  const date  = new Date(str.toString()).toISOString().split('T')[0]
  return ConvertDateSlashFormat(date)
}
export const formatDateApi = (str: any)=>{
  const date  = dayjs(str, 'DD/MM/YYYY').format('DD/MM/YYYY')
  return date 
}
export const ConvertDateSlashFormat = (str: string) => {
  const [year, month, day] = str.split('-');
  return `${day}/${month}/${year}`;
}
export const ConvertDateMinusFormat = (str: string) => {
  const [day, month, year ] = str.split('/');
  return `${ year}-${month}-${day}`;
}
export const validateDate = (date: string, minDate: string) => {
  const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  if (!regex.test(date)) return false;

  const [day, month, year] = date.split('/').map(Number);
  const dateObj = new Date(year, month - 1, day);

  if (
    dateObj.getFullYear() !== year ||
    dateObj.getMonth() !== month - 1 ||
    dateObj.getDate() !== day
  ) {
    return false;
  }

  const [minDay, minMonth, minYear] = minDate.split('/').map(Number);
  const minDateObj = new Date(minYear, minMonth - 1, minDay);

  return dateObj >= minDateObj;
};

export function checkSeatBooked(seats: any[], seatId: string): boolean {
  if (!seats) return false;
  const foundSeat = seats.find((seat) => seat.label === seatId);
  return !!foundSeat;
}

export function convertforVIP(label: string): string {
  return label.replace(/VIP\s+/g, "V");
}
export function unConvertforVIP(label: string): string {
  return label.replace(/V(\d+)/g, "VIP $1");
}

export function convertData(orderItems: OrderItem[]): ConvertedData {
  const trips: OrderItem[] = [];
  const returnTrips: OrderItem[] = [];

  let departureFrom = "";
  let arrivalFrom = "";

  orderItems.forEach(item => {
    if (item.is_return_trip === 1) {
      returnTrips.push(item);
      if (!arrivalFrom) {
        arrivalFrom = item.place_of_birth;
      }
    } else {
      trips.push(item);
      if (!departureFrom) {
        departureFrom = item.place_of_birth;
      }
    }
  });

  const hasReturnTrip = returnTrips.length > 0;

  return {
    hasReturnTrip,
    departureFrom,
    arrivalFrom,
    trips,
    returnTrips
  };
}