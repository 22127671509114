import { Col, Row } from 'antd';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { homePaths } from '@app/router/path';
import DropDownOffice from './DropDownOffice';

const Footer = () => {
    const { t: trans } = useTranslation();
    return (
        <>
            <img className="footer-wave" src="/assets/png/wave.png" alt="" />
            <div id="footer">
                <div className="footer-container container">
                    <img className="footer-logo" src="/assets/png/logo2.png" alt="lightning68" />
                    <Row gutter={30}>
                        <Col xs={24} md={8}>
                            <h3 className="footer-heading">{trans('footer.headQuarter')}</h3>
                            <div className="footer-infor">
                                <div className="footer-infor-item">
                                    <img src="/assets/png/location.png" alt="vị trí" />
                                    <p>
                                        {trans('footer.address')}
                                    </p>
                                </div>
                                <div className="footer-infor-item">
                                    <img src="/assets/png/phone.png" alt="điện thoại" />
                                    <p>
                                        Hotline: <span>092 722 55 99</span>
                                    </p>
                                </div>
                                <div className="footer-infor-item">
                                    <img src="/assets/png/ticket.png" alt="liên hệ đặt vé" />
                                    <p>
                                        {trans('footer.booking')} <span>092 722 55 99</span>
                                    </p>
                                </div>
                                <div className="footer-infor-item">
                                    <img src="/assets/png/mail.png" alt="mail" />
                                    <p>congtycpbienson@gmail.com</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={16}>
                            <h3 className="footer-heading">{trans("footer.office")}</h3>
                            <Row gutter={[24, 16]}>
                                <Col xs={24} md={12}>
                                    <DropDownOffice locale='phuquoc' phone='02973919666' mail='congtycpbienson@gmail.com' />
                                </Col>
                                <Col xs={24} md={12}>
                                    <DropDownOffice locale='laison' phone='02973919666' mail='congtycpbienson@gmail.com' />
                                </Col>
                                {/* <Col xs={24} md={12}>
                                    <div className="footer-link-item">
                                    {trans("footer.offices.HCM")}

                                        <img src="/assets/png/arrow-up-right.png" alt="đến trang văn phòng đại diện" />
                                    </div>
                                </Col> */}
                                {/* <Col xs={24} md={12}>
                                    <div className="footer-link-item">
                                    {trans("footer.offices.namdu")}

                                        <img src="/assets/png/arrow-up-right.png" alt="đến trang văn phòng đại diện" />
                                    </div>
                                </Col>
                                <Col xs={24} md={12}>
                                    <div className="footer-link-item">
                                    {trans("footer.offices.cantho")}

                                        <img src="/assets/png/arrow-up-right.png" alt="đến trang văn phòng đại diện" />
                                    </div>
                                </Col>
                                <Col xs={24} md={12}>
                                    <div className="footer-link-item">
                                    {trans("footer.offices.soctrang")}

                                        <img src="/assets/png/arrow-up-right.png" alt="đến trang văn phòng đại diện" />
                                    </div>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>

                    <div className="footer-policy">
                        <Row gutter={30}>
                            <Col xs={8}>
                                <a href={homePaths.baseurl + homePaths.policy}>{trans('footer.polices.safe')}</a>
                            </Col>
                            <Col xs={8}>
                                <a href={homePaths.baseurl + homePaths.baggage}>{trans('footer.polices.luggage')}</a>
                            </Col>
                            <Col xs={8}>
                                <a href={homePaths.baseurl + homePaths.ticket}>{trans('footer.polices.ticket')}</a>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div id="footer-copyright">
                    <span>Copyright © 2024 Lightning Ship. All Rights Reserved</span>
                </div>
            </div>
        </>
    );
};

export default Footer;
