import { useTranslation } from 'react-i18next';
import './style.scss';

type SlideProp = {
    title?: string
}
const Slider = ({title}:SlideProp) => {
    const { t: trans } = useTranslation()
    return (
        <div id='slide-container'>
            <div id="slide-header">
                <img className="boat-img" src="/assets/images/bannerpng.png" alt="" />
                <img className="boat-img mobile" src="/assets/images/bannermb.jpg" alt="" />
                <span/>
                <img className="curve-img" src="/assets/png/curve-bot.png" alt="" />
            </div>
            <h1 className="title-page">{title ? title :trans('slide.slogan')}</h1>
        </div>
    );
};

export default Slider;
