import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface Passengers {

    passengerName: string;
    idCard: string;
    dob:Date;
    number?: string;
    country?: string;
    birthPlace?: string;
    email?: string;
    typeTicket: {
        value: string;
        label: string;
    };
}
export interface infoPassengerState {
    passengers: Passengers[],

}

const initialState: infoPassengerState = {
    passengers: []
}

const inforPassengerSlice = createSlice({
    name: 'info-passenger',
    initialState,
    reducers: {
        setInfoPassenger: (state, action) => {
            state.passengers = action.payload
        },
        clearInfoPassenger: (state, action) => {
            state.passengers = []
        }
    },
});

export const getInfoPassenger = (state: RootState): Passengers[] => state.infoPassenger.passengers

export const { setInfoPassenger, clearInfoPassenger } = inforPassengerSlice.actions;
export default inforPassengerSlice.reducer;