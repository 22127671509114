import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationEN from './en/translation.json'
import translationVN from './vn/translation.json'

const resources = {
  en: {
    translation: translationEN,
  },
  vn: {
    translation: translationVN,
  },
}
export const settingLang = [
    { label: 'VN', value: 'vn' },
    { label: 'EN', value: 'en' },
]
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng:
      i18n.language ||
      window.localStorage.i18nextLng ||
      process.env.REACT_APP_DEFAULT_LANGUAGE,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
