import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { Boat } from '@app/types/boat';


export interface selectedBoatsState {
    departure_boat: Boat,
    departure_route_name: string,
    arrival_route_name: string,
    arrival_boat: Boat,
    departure_boat_canbook?: any,
    arrival_boat_canbook?: any,
}

const initialState: selectedBoatsState = {
    departure_boat: {
        id: '',
        boat_schedule_id: '',
        boat_route_id: '',
        status: '',
        boat_id: '',
        boat_code: '',
        departure_time: ''
    },
    departure_route_name: '',
    arrival_route_name: '',
    arrival_boat: {
        id: '',
        boat_schedule_id: '',
        boat_route_id: '',
        status: '',
        boat_id: '',
        boat_code: '',
        departure_time: ''
    },
    departure_boat_canbook: [],
    arrival_boat_canbook: []
};

const selectedBoatsSlice = createSlice({
    name: 'selectedBoats',
    initialState,
    reducers: {
        setSelectedBoats: (state, action) => {
            state.arrival_boat = action.payload.arrival_boat
            state.departure_boat = action.payload.departure_boat
            state.arrival_route_name = action.payload.arrival_route_name
            state.departure_route_name = action.payload.departure_route_name
            state.departure_boat_canbook = action.payload.departure_boat_canbook
            state.arrival_boat_canbook = action.payload.arrival_boat_canbook
        },
        clearSelectedBoats: (state, action) => {
            state.arrival_boat = {
                id: '',
                boat_schedule_id: '',
                boat_route_id: '',
                status: '',
                boat_id: '',
                boat_code: '',
                departure_time: ''
            }
            state.departure_boat = {
                id: '',
                boat_schedule_id: '',
                boat_route_id: '',
                status: '',
                boat_id: '',
                boat_code: '',
                departure_time: ''
            }
            state.arrival_route_name = ''
            state.departure_route_name = ''
            state.departure_boat_canbook = []
            state.arrival_boat_canbook = []
        }

    },
});

export const getSelectedBoats = (state: RootState): selectedBoatsState => state.selectedBoats
export const { setSelectedBoats, clearSelectedBoats } = selectedBoatsSlice.actions;
export default selectedBoatsSlice.reducer;