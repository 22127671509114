import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import './style.scss';
import { useState } from "react";

type Prop = {
    locale: string;
    phone: string,
    mail?: string
}

const DropDownOffice = ({ locale, phone, mail }: Prop) => {
    const { t: trans } = useTranslation();

    const [show, setShow] = useState(false);
    const toggleInfor = () => {
        setShow(pre => !pre);
    }
    return (
        <>
            <div className="footer-link-item" onClick={toggleInfor}>
                {trans(`footer.offices.${locale}`)}
                <img src="/assets/png/arrow-up-right.png" alt="đến trang văn phòng đại diện" className={`${show && 'rotate'}`} />
            </div>
            {
                show && <div className={`dropdown-info ${show && 'active'}`} >
                    <div className="footer-infor-item">
                        <img src="/assets/png/location.png" alt="vị trí" />
                        <p>
                            {trans(`footer.addresslocal.${locale}`)}
                        </p>
                    </div>
                    <div className="footer-infor-item">
                        <img src="/assets/png/phone.png" alt="điện thoại" />
                        <p>
                            <span>{phone}</span>
                        </p>
                    </div>
                    <div className="footer-infor-item wrap">
                        <img src="/assets/png/mail.png" alt="mail" />
                        <p>{mail}</p>
                    </div>
                </div>
            }

        </>
    )
}

export default DropDownOffice;